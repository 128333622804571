import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn, loggedOut } from './auth';
import { LOGIN_ROUTE } from './Router';
import useAccessToken from './useAccessToken';

const Logout = () => {
  const [accessToken, setAccessToken] = useAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn(accessToken)) navigate(LOGIN_ROUTE);
  }, [accessToken, navigate]);

  useEffect(() => {
    setAccessToken(loggedOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default Logout;
