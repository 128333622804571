import { useCallback, useContext } from "react";
import Auth, { AuthPayload, AuthState } from "./auth";
import AccessTokenContext from "./AccessTokenContext";

const useAccessToken = (): [AuthState, (user: AuthState) => void] => {
  const [accessToken, _setAccessToken] = useContext(AccessTokenContext);
  if (_setAccessToken == null)
    throw Error("AccessTokenProvider must be init before using the hook");

  const setAccessToken = useCallback(
    (auth: AuthState) => {
      Auth.fold(
        () => {
          localStorage.removeItem("google_token");
        },
        (_accessToken: AuthPayload) => {
          if (_accessToken) {
            _accessToken.access_token &&
              localStorage.setItem(
                "google_token",
                JSON.stringify(_accessToken)
              );
          } else {
            localStorage.removeItem("google_token");
          }
        }
      )(auth);

      _setAccessToken(auth);
    },
    [_setAccessToken]
  );
  return [accessToken, setAccessToken];
};

export default useAccessToken;
