import axios from "axios";
import useService from "./useService";
import { AuthPayload } from "./auth";

export type EmailPassword = {
  username: string;
  password: string;
};

const useAuthenticate = () =>
  useService((body: EmailPassword) => {
    return axios.post<AuthPayload>("/login", body);
  });

const useLogout = () => useService(() => axios.get("/logout"));

const AuthenticateService = {
  useAuthenticate,
  useLogout,
};

export default AuthenticateService;
