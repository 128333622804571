import AccessTokenProvider from "./AccessTokenProvider";
import Router from "./Router";

function App() {
  return (
    <AccessTokenProvider>
      <Router />
    </AccessTokenProvider>
  );
}

export default App;
