import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "./Router";
import AuthenticateService from "./useLogin";
import jwtDecode from "jwt-decode";
import useAccessToken from "./useAccessToken";
import Auth, { AuthPayload, loggedIn } from "./auth";
import { isSuccess } from "./RemoteData";

const hasExpired = (token: AuthPayload): boolean =>
  !!token?.access_token &&
  jwtDecode<{ username: string; expires_in: number }>(token.access_token!)
    .expires_in < Math.round(new Date().getTime() / 1000);

const GoogleOAuthv2 = () => {
  const navigate = useNavigate();
  const [, setAccessToken] = useAccessToken();
  const [logging] = AuthenticateService.useAuthenticate();

  const login = useCallback(
    (event: any) => {
      event.preventDefault();
      (async () => {
        const username = event.target.username.value;
        const password = event.target.password.value;
        const anwser = await logging({ username, password });
        if (!isSuccess(anwser)) return;
        setAccessToken(loggedIn(anwser.value));
        navigate(DASHBOARD_ROUTE);
      })();
    },
    [logging, navigate, setAccessToken]
  );

  return (
    <form className="flex flex-wrap gap-4 px-10" onSubmit={login}>
      <input
        type="text"
        className="p-4 border border-rounded w-full"
        placeholder="username"
        name="username"
        id="username"
      />
      <input
        type="password"
        className="p-4 border border-rounded w-full"
        placeholder="password"
        name="password"
        id="password"
      />
      <button type="submit" className="p-4 bg-orange-300 w-full">
        Sign in
      </button>
    </form>
  );
};

export const Login = () => {
  return (
    <div className="flex justify-center h-screen items-center">
      <GoogleOAuthv2 />
    </div>
  );
};

interface AuthenticatedProps {
  element: JSX.Element;
}

export const Authenticated = ({ element }: AuthenticatedProps): JSX.Element => {
  const navigate = useNavigate();
  const [user] = useAccessToken();

  const isAuthenticated = (token: AuthPayload) => {
    return !token || hasExpired(token);
  };

  useEffect(() => {
    Auth.fold(
      () => {
        navigate(LOGIN_ROUTE);
      },
      (accessToken) => {
        // replace this by checking expired_time of the token
        if (isAuthenticated(accessToken)) {
          navigate(LOGIN_ROUTE);
        }
      }
    )(user);
    // Only disabled when you want to do action on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return Auth.fold(
    () => <span>{"redirect"}</span>,
    (accessToken: AuthPayload) => {
      if (isAuthenticated(accessToken)) return <span>{"redirect"}</span>;
      return element;
    }
  )(user);
};
