import jwtDecode from "jwt-decode";

const decode = (token: string | null): any | false =>
  !!token && jwtDecode(token);

export type AuthPayload = { access_token: string | null } | undefined;

type LoggedOut = { _tag: "loggedOut" };
type LoggedIn = { _tag: "loggedIn"; authPayload: AuthPayload };

export type AuthState = LoggedIn | LoggedOut;

export const loggedOut = (): AuthState => ({ _tag: "loggedOut" });

export const loggedIn = (authPayload: AuthPayload): AuthState => ({
  _tag: "loggedIn",
  authPayload: authPayload,
});

const fold =
  <R>(whenLoggedOut: () => R, whenLoggedIn: (user: AuthPayload) => R) =>
  (authState: AuthState) => {
    if (isLoggedOut(authState)) return whenLoggedOut();
    return whenLoggedIn(authState.authPayload);
  };

const _isLoggedIn = (ma: AuthState): ma is LoggedIn => ma._tag === "loggedIn";

export const isLoggedIn: (rd: AuthState) => rd is LoggedIn = _isLoggedIn;

const _isLoggedOut = (ma: AuthState): ma is LoggedOut =>
  ma._tag === "loggedOut";

const isLoggedOut: (rd: AuthState) => rd is LoggedOut = _isLoggedOut;

export const getUser = (authState: AuthState): any | null | false =>
  fold(
    () => null,
    (authPayload) => !!authPayload && decode(authPayload.access_token)
  )(authState);

const Auth = {
  loggedOut,
  loggedIn,
  fold,
};

export default Auth;
