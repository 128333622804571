import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import { Authenticated, Login } from "./Login";
import { ReactNode } from "react";
import Logout from "./Logout";

export const DASHBOARD_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const CALLBACK_ROUTE = "/callback";

interface Props {
  children: ReactNode;
}

const ProtectedPage = ({ children }: Props) => {
  return <Authenticated element={<>{children}</>} />;
};

function Router() {
  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route path={LOGOUT_ROUTE} element={<Logout />} />
      <Route path={CALLBACK_ROUTE} element={<>Hello</>} />
      <Route
        path={DASHBOARD_ROUTE}
        element={
          <ProtectedPage>
            <Dashboard />
          </ProtectedPage>
        }
      />
    </Routes>
  );
}

export default Router;
