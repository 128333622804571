import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { failure, loading, notAsked, RemoteData, success } from './RemoteData';

export type Service<T, R> = [
  fetcher: (params?: R) => Promise<RemoteData<T, unknown>>,
  result: RemoteData<T, unknown | AxiosError>,
  setAs: (as: RemoteData<T, unknown | AxiosError>) => void,
];

const useService = <T, R>(fetcher: (params: R) => Promise<AxiosResponse<T>>): Service<T, R> => {
  const [result, setResult] = useState(notAsked<T, unknown | AxiosError>());

  const setAs = useCallback((as: RemoteData<T, unknown | AxiosError>) => {
    setResult(as);
  }, []);

  const call = useCallback(
    async (params: any): Promise<RemoteData<T, unknown>> => {
      setResult(loading());
      try {
        const response = await fetcher(params);
        setResult(success(response.data));
        return success(response.data);
      } catch (err: unknown | AxiosError) {
        setResult(failure(err));
        return failure(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [call, result, setAs];
};

export default useService;
