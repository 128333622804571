import { TokenResponse } from '@react-oauth/google';
import axios from 'axios';
import { ReactNode, useState } from 'react';
import AccessTokenContext from './AccessTokenContext';
import Auth, { AuthPayload, AuthState } from './auth';

interface Props {
  children: ReactNode;
  accessToken?: AuthPayload;
}

const useTokenState = (defaultValue: TokenResponse): [AuthState, React.Dispatch<React.SetStateAction<AuthState>>] => {
  const [token, setToken] = useState(defaultValue ? Auth.loggedIn(defaultValue) : Auth.loggedOut());

  return [token, setToken];
};


const AccessTokenProvider = ({ children, accessToken }: Props) => {
  const maybeToken = JSON.parse(localStorage.getItem('google_token')!);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken?.access_token || maybeToken?.access_token}`;

  const [token, setToken] = useTokenState(
    accessToken || maybeToken?.access_token,
  );

  return <AccessTokenContext.Provider value={[token, setToken]}>{children}</AccessTokenContext.Provider>;
};

export default AccessTokenProvider;
